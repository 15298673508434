import React, {Component, Fragment} from "react";
import {getMessage} from "./util";

var dateFormat = require("dateformat");

dateFormat.i18n = {
  dayNames: [
    "Sun",
    "Mon",
    "Tue",
    "Wed",
    "Thu",
    "Fri",
    "Sat",
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday"
  ],
  monthNames: [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
    "enero",
    "febrero",
    "marzo",
    "abril",
    "mayo",
    "junio",
    "julio",
    "agosto",
    "septiembre",
    "octubre",
    "noviembre",
    "diciembre"
  ],
  timeNames: ["a", "p", "am", "pm", "A", "P", "AM", "PM"]
};

class Header extends Component {
  render() {
    return (
         <Fragment>
            <div className="row justify-content-center">
              <div className="col-12 col-sm-11 col-md-9 col-lg-7 text-center">
                <img
                  className="img-fluid logo"
                  alt="Logo"
                  src={this.props.election.configuration.logo_url}
                />
              </div>
            </div>
            <div className="row justify-content-center">
              <div className="col-12 col-sm-11 col-md-9 col-lg-7 text-center mb-1">
                <h3>{this.props.election.name}</h3>
                <h5>{this.props.election.organization_name}</h5>
              </div>
            </div>


           <div className="row justify-content-center">
             <div className="col-12 col-sm-11 col-md-9 col-lg-7">
            <div className="row justify-content-center mb-1">

              <div className="col-12 col-sm-6 col-md-4 mb-1 p-0">
                <BoxDate
                    messages={this.props.messages}
                  title={getMessage('start_date',this.props.messages)}
                  date={this.props.election.configuration.start_date}
                />
              </div>
              <div className="col-12 col-sm-6 col-md-4 mb-1 p-0">
                <BoxDate
                    messages={this.props.messages}
                  title={getMessage('end_date',this.props.messages)}
                  date={this.props.election.configuration.end_date}
                />
              </div>
              <div className="col-12 col-md-4 mb-1 p-0">
                <BoxState
                    messages={this.props.messages}
                    title={getMessage('state',this.props.messages)}
                    state={this.props.election.state} />
              </div>
            </div>

            <div className="row justify-content-center">
              <div className="col-12">
                <p>
                {getMessage('date_label',this.props.messages)}: {dateFormat(new Date(), "longDate")},{" "}
                  {dateFormat(new Date(), "longTime")}
                </p>
              </div>
            </div>
             </div>
           </div>
         </Fragment>

    );
  }
}

class BoxState extends Component {
  translate = state => {
    switch (state) {
      case "setup":
        return getMessage('state_setup',this.props.messages);
      case "open":
        return getMessage('state_open',this.props.messages);
      case "closed":
        return getMessage('state_closed',this.props.messages);
      case "summed":
        return getMessage('state_summed',this.props.messages);
      case "decrypting":
        return getMessage('state_decrypting',this.props.messages);
      case "finished":
        return getMessage('state_finished',this.props.messages);
      default:
        return state;
    }
  };
  render() {
    return (
        <div className="row p-2 rounded h-100 EvColorGrisLight m-1">
          <div className="col-12">
            <div className="row">
              <strong>
                {this.props.title}
              </strong>
            </div>
            <div className="row">
              <div className="col-12 text-center p-0">
                {this.translate(this.props.state)}
              </div>
            </div>
          </div>


        </div>
    );
  }
}

class BoxDate extends Component {


  render() {
    const date = this.props.date.$date?this.props.date.$date:new Date(this.props.date);

    return (
      <div className="row EvColorGrisLight p-2 m-1 rounded h-100">
        <div className="col-12">
          <div className="row">
            <strong>
              {this.props.title}
            </strong>
          </div>
          <div className="row">
            <div className="col-12 text-center p-0">
                {dateFormat(date, "dd 'de' mmmm 'de' yyyy")}
                <br />
                {dateFormat(date, "HH:MM:ss Z")}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Header;