import React, {Component, Fragment} from "react";
import {getMessage} from "./util";

const version = require('../package.json');

class Footer extends Component {


    render() {
        const contact = (this.props.election && this.props.election.configuration.contact)?this.props.election.configuration.contact:({

        email: getMessage('support_phone',this.props.messages),
        phone: getMessage('support_mail',this.props.messages)

    });

        return (
            <Fragment>
                <div className="row EvColor text-white p-1 justify-content-center">
                    <div className="col-12 mb-2  text-center">

                        {getMessage('support_title',this.props.messages)}: {contact.phone} {contact.email}

                        <br/>
                        <small>{getMessage('footer_message',this.props.messages)}</small>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 text-right">
                        <small>© {getMessage('evoting',this.props.messages)} {version.version}</small>
                    </div>
                </div>
            </Fragment>
        );
    }
}


export default Footer;
