import React, { Component } from "react";
import { Pie } from "react-chartjs-2";
import { Card, CardBody, CardText } from "reactstrap";
import {getMessage} from "./util";

class RolloutSummaryW extends Component {

  render() {
      var labels = [getMessage('label_ready',this.props.messages), getMessage('label_electronic_voted',this.props.messages)];
      var datain = [
              this.props.snapshot.weighted.ready /  this.props.election.configuration.advanced_config.factor,
              this.props.snapshot.weighted.electronic_voted /  this.props.election.configuration.advanced_config.factor
            ];

      if (this.props.snapshot.weighted.manual_voted > 0) {
        labels.push(getMessage('label_paper_voted',this.props.messages));
          datain.push(this.props.snapshot.weighted.manual_voted /  this.props.election.configuration.advanced_config.factor);
      }
      const data = {
        labels: labels,
        datasets: [
          {
            data: datain,
              backgroundColor: ["#C6CCD4", "#0B1224"],
              hoverBackgroundColor: ["#C6CCD4", "#0B1224"]
          }
        ]
      };

      const chartOptions = {
          responsive:true,
          plugins: {
              legend: {
                  position: "top",
                  onClick: null
              },
          },
      };

      return (
        <div>
            <div className="row justify-content-center">
                <div className="col-7 col-sm-6 col-md-5" >
              <Pie data={data} options={chartOptions} />
                    </div>
            <div className="col-12 col-sm-4">
              <div className="row justify-content-center">
                <div className="col-6 col-sm-10 mt-2">
                  <BoxInfo
                    percentage={(
                      (100 * (this.props.snapshot.weighted.electronic_voted+this.props.snapshot.weighted.manual_voted)) /
                        (this.props.snapshot.weighted.electronic_voted+
                            this.props.snapshot.weighted.manual_voted+
                            this.props.snapshot.weighted.ready+
                            this.props.snapshot.weighted.registered)

                    )
                      .toFixed(2)
                      .toLocaleString(navigator.language, {
                        minimumFractionDigits: 2
                      })}
                    progress={(
                        (this.props.snapshot.weighted.electronic_voted +
                      this.props.snapshot.weighted.manual_voted) / this.props.election.configuration.advanced_config.factor
                    ).toLocaleString(navigator.language, {
                      minimumFractionDigits: 0
                    })}
                    total={(
                        (this.props.snapshot.weighted.electronic_voted +
                      this.props.snapshot.weighted.manual_voted +
                      this.props.snapshot.weighted.ready)  / this.props.election.configuration.advanced_config.factor
                    ).toLocaleString(navigator.language, {
                      minimumFractionDigits: 0
                    })}
                    label={getMessage('total_weighted',this.props.messages)}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }
  }


class BoxInfo extends Component {
  render() {
    return (
      <div>
        <Card>
          <CardBody>
            <CardText>
              <b>{this.props.percentage} %</b>
              <br />({this.props.progress} de {this.props.total})<br />
              {this.props.label}
            </CardText>
          </CardBody>
        </Card>
      </div>
    );
  }
}

export default RolloutSummaryW;