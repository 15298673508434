import React, {Component, Fragment} from "react";
import 'chart.js/auto';
import axios from "axios";
import LoadingSpinner from "./LoadingSpinner";
import {getMessage} from "./util";
import * as echarts from 'echarts';
import ReactEcharts from "echarts-for-react";


class VotesByTimeSlot extends Component {
    constructor(props) {
        super(props);
        this.state = {
            votes: null,
            loading: false
        };
    }

    componentDidMount() {
        this.loadVotesByTimeSlot(this.props.election_id);
    }

    loadVotesByTimeSlot = election_id => {
        this.setState({loading: true}, () =>
            axios({
                method: "get",
                url:
                    process.env.REACT_APP_BACKUP_URL +
                    "/" +
                    this.props.election_id +
                    "/votesByTimeSlot.json"
            })
                .then(response => {
                    this.setState({
                        loading: false,
                        votes: Object.entries(response.data.time_slot).map(function (v) {
                            return {x: 1000 * parseInt(v[0]), y: v[1].electronic_voted};
                        })
                    });
                })
                .catch(error => {
                    if (error.response && error.response.status === 404) {
                        axios({
                            method: "get",
                            url:
                                process.env.REACT_APP_API_URL +
                                "/election/" +
                                election_id +
                                "/votesByTimeSlot"
                        })
                            .then(response => {
                                this.setState({
                                    loading: false,
                                    votes: Object.entries(response.data.time_slot).map(function (v) {
                                        return {
                                            x: 1000 * parseInt(v[0]),
                                            y: v[1].electronic_voted
                                        };
                                    })
                                });
                            })
                            .catch(error => {
                                this.setState({loading: false});
                            });
                    } else {
                        this.setState({loading: false});
                    }
                })
        );
    };


    render() {

        const formatter = new Intl.DateTimeFormat('es-es', {
            year: 'numeric',
            month: 'numeric',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric'
        });

        const formatter2 = new Intl.DateTimeFormat('es-es', {
            hour: 'numeric',
            minute: 'numeric'
        });

        const date_format = (date, full) => {
            //tomas: legacy, old elections does not have resolution
            const resolution = this.props.election.configuration.advanced_config.count_votes_resolution_seconds || 3600;
            const since = new Date(date)
            const until = new Date(date + resolution * 1000);
            if (since.getHours() === 0 || full || since.getHours() - (resolution / 3600) < 0) {
                return (formatter.format(since) + " - " + formatter2.format(until))
            } else
                return (formatter2.format(since) + " - " + formatter2.format(until))
        }


        if (this.state.votes === null) {
            return <div>{this.state.loading && <LoadingSpinner/>}</div>;
        } else {
            const data = {
                labels: [date_format(this.state.votes[0].x, true)].concat(this.state.votes.slice(1).map(v => date_format(v.x))),
                datasets: [
                    {
                        label: "Votos Electrónicos ",
                        backgroundColor: "#C6CCD4",
                        data: this.state.votes.map(v => v.y),

                    }
                ]
            };


            const option = {
                darkMode: "true",
                tooltip: {
                    trigger: "axis",
                    axisPointer: {
                        type: "line"
                    },
                    position: function (pt) {
                        return [pt[0], '30%'];
                    }
                },
                title: {
                    left: 'center',
                    text: 'Votos Electrónicos',
                    textStyle: {
                        color: '#000000',
                        fontSize: 25,
                        transform : 'uppercase',
                    },
                    },

                toolbox: {
                    show: false,
                    feature: {
                        dataView: {
                            title: "Votos",
                            readOnly: true,
                            lang: ['Datos', 'Cerrar']
                        },
                        restore: {
                            title: "Restaurar"
                        },
                        saveAsImage: {
                            title: "Guardar como imagen"
                        },
                    }
                },
                xAxis: {
                    type: 'category',
                    boundaryGap: [0, '2%'],
                    data: data.labels
                },
                yAxis: {
                    type: 'value',
                    boundaryGap: [0, '2%']
                },
                dataZoom: [
                    {
                        type: "inside",
                        fillerColor: "rgba(46, 50, 56, 0.05)",
                        borderColor: "rgba(46, 50, 56, 0)",
                        textStyle: {
                            color: "rgba(28, 31, 35, 0.6)",
                        },
                        dataBackground: {
                            areaStyle: {
                                color: "rgba(46, 50, 56, 0.09)"
                            }
                        },
                        left: '12%',
                        right: '12%',
                        moveHandleSize:12,
                        handleIcon: "path://M15,30C15,21.71270751953125,21.71270751953125,15,30,15C38.28729248046875,15,45,21.71270751953125,45,30C45,38.28729248046875,38.28729248046875,45,30,45C21.71270751953125,45,15,38.28729248046875,15,30Z",
                        handleStyle: {
                            color: "rgb(198, 204, 212)",
                            shadowColor: "rgba(45,45,45,0.1)",
                            shadowBlur: "6px",
                        },
                        handleSize: 24


                    }
                ],
                series: [
                    {
                        name: 'Votos',
                        type: 'bar',
                        symbol: 'circle',
                        symbolSize: 5,
                        sampling: 'lttb',
                        itemStyle: {
                            color: '#D1D7E1'
                        },
                        areaStyle: {
                            color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                                {
                                    offset: 0,
                                    color: 'rgb(198, 204, 212)'
                                },
                                {
                                    offset: 1,
                                    color: 'rgb(198, 204, 212)'
                                }
                            ])
                        },
                        data: data.datasets[0].data
                    }
                ]
            };









            return (
                <Fragment>

                    {this.state.votesByTimeSlot !== null && (
                        <Fragment>


                            <div className="row justify-content-center EvColor text-white">
                                <div className="col-12 col-sm-11 col-md-9 col-lg-7  p-3">
                                    <h4>{getMessage('votes_time_slot_title', this.props.messages)}</h4>
                                </div>
                            </div>
                            <br/>
                            <div className="row justify-content-center ">
                                <div className="col-12 col-sm-10 col-md-9 col-lg-7 EvColorGrisLight p-3">

                                    <ReactEcharts className="mt-3 " option={option} style={{height: '500px', width: '100%'}}/>

                                     </div>
                            </div>
                        </Fragment>
                    )}
                </Fragment>
            );
        }
    }
}

export default VotesByTimeSlot;