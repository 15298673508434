import React, {Component, Fragment} from "react";
import Header from "./Header";
import VotesByTimeSlot from "./VotesByTimeSlot";
import TurnoutTable from "./TurnoutTable";
import ResultsTable from "./ResultsTable";
import axios from "axios";
import Footer from "./Footer";
import RolloutSummary from "./RolloutSummary";
import RolloutSummaryW from "./RolloutSummaryW";
import TurnoutDropInTable from "./TurnoutDropInTable";
import {getMessage} from "./util";
import queryString from 'query-string';


class Home extends Component {

    constructor(props) {

        super(props);
        this.state = {
            source_restricted: false,
            snapshot: null,
            election: null,
            loading: false,
            messages: null,
            showTotalResults: null
        };
    }

    componentDidMount() {
        this.loadElection(this.props.match.params.election_id);
        this.loadSnapshot(this.props.match.params.election_id);
        const parsed = queryString.parse(window.location.search);
        this.setState({source_restricted: parsed.auth?(parsed.auth==="true"):false})

    }

    loadElection = election_id => {
        this.setState({loading: true}, async () => {

            const getElection = async () => {
                try {
                    return (await axios({
                        method: "get",
                        url: process.env.REACT_APP_BACKUP_URL +
                            "/" +
                            election_id +
                            "/header.json"
                    }));
                } catch (error) {
                    if (error.response && error.response.status === 404) {
                        try {
                            return (await axios({
                                method: "get",
                                url:
                                    process.env.REACT_APP_API_URL +
                                    "/election/" +
                                    election_id
                            }));
                        } catch (error) {
                            throw(error);
                        }

                    }
                }
            }

            const getTemplate = async (template) => {
                if (template) {
                    try {
                        return ((await axios({
                            method: "get",
                            url: template[0].content
                        })))
                    } catch (error) {
                        return (null);
                    }
                }
                return (null);
            }

            const election = await getElection();

            const messages = await getTemplate(election.data.configuration.templates.filter(t => t.name === 'messages.json'))

            this.setState({election: election.data, messages: messages ? messages.data : null, loading: false});

            this.setState({showTotalResults: this.state.election.configuration.advanced_config.choose_percentage_votes})
        })
    }


    loadSnapshot = election_id => {

        this.setState({loading: true}, () =>
            axios({
                method: "get",
                url:
                    process.env.REACT_APP_BACKUP_URL +
                    "/" +
                    election_id +
                    "/snapshot.json"
            })
                .then(response => {
                    this.setState({
                        loading: false,
                        snapshot: response.data
                    });
                })
                .catch(error => {
                    if (error.response && error.response.status === 404) {
                        axios({
                            method: "get",
                            url:
                                process.env.REACT_APP_API_URL +
                                "/election/" +
                                election_id +
                                "/snapshot"
                        })
                            .then(response => {
                                this.setState({
                                    loading: false,
                                    snapshot: response.data
                                });
                            })
                            .catch(error => {
                                this.setState({loading: false});
                            });
                    } else {
                        this.setState({loading: false});
                    }
                })
        );
    };

    render() {

        const thestyle = (this.state.election && this.state.election.configuration.templates.filter(t => t.name === "vote.css").length > 0) ? this.state.election.configuration.templates.filter(t => t.name === "vote.css")[0].content : process.env.PUBLIC_URL + "/vote.css";

        return (
            <div className="container-fluid">
                <link rel="stylesheet" type="text/css" href={thestyle}/>
                <div className="sticky-top  text-center  d-flex flex-row-reverse">
                    <div className="p-1 EvColor TopLogo rounded-bottom mr-4">
                        <img src="/logo-blanco.png" alt="logo"/>
                    </div>
                </div>
                {this.state.election != null && this.state.snapshot != null && (
                    <Fragment>


                        <Header election={this.state.election} messages={this.state.messages}/>
                        <ResultsTable districts={this.state.snapshot.districts}
                                      source_restricted={this.state.source_restricted}
                                      votantes={this.state.snapshot}
                                      election_id={this.props.match.params.election_id}
                                      messages={this.state.messages}
                                      showTotalResults={this.state.showTotalResults}
                        />
                        <br/><br/>
                        <section className="row justify-content-center">
                            <div className="col-12 col-sm-11 col-md-9 col-lg-7 text-center">
                                <h5>Total de votos recibidos: <strong>{this.state.snapshot.voters.electronic_voted}</strong></h5>
                            </div>

                        </section>
                        {!this.state.election.configuration.advanced_config.open_registration && (this.state.election.configuration.advanced_config.weighted ? (
                            <React.Fragment>
                                <div className="row EvColorGris justify-content-center text-white p-3">
                                    <div className="col-12 col-sm-11 col-md-9 col-lg-7">
                                        <h4>{getMessage('participation', this.state.messages)}</h4>
                                    </div>
                                </div>
                                <br/>
                                <div className="row  justify-content-center">
                                    <div className="col-12 col-sm-11 col-md-9 col-lg-7">
                                        <RolloutSummary election={this.state.election} snapshot={this.state.snapshot} messages={this.state.messages}/>
                                    </div>
                                </div>
                                <div className="row EvColorGris justify-content-center text-white p-3">
                                    <div className="col-12 col-sm-11 col-md-9 col-lg-7">
                                        <h4>{getMessage('weighted_participation', this.state.messages)}</h4>
                                    </div>
                                </div>

                                <br/>
                                <div className="row  justify-content-center">
                                    <div className="col-12 col-sm-11 col-md-9 col-lg-7">
                                        <RolloutSummaryW election={this.state.election} snapshot={this.state.snapshot} messages={this.state.messages}/>
                                    </div>
                                </div>
                            </React.Fragment>
                        ) : (
                            <React.Fragment>
                                <div className="row EvColorGris justify-content-center text-white p-3">
                                    <div className="col-12 col-sm-11 col-md-9 col-lg-7">
                                        <h4>{getMessage('participation_title', this.state.messages)}</h4>
                                    </div>
                                </div>
                                <br/>

                                <div className="row  justify-content-center">
                                    <div className="col-12 col-sm-11 col-md-9 col-lg-7">
                                        <RolloutSummary election={this.state.election} snapshot={this.state.snapshot} messages={this.state.messages}/>
                                    </div>
                                </div>
                            </React.Fragment>
                        ))}
                    </Fragment>
                )}

                <br/><br/>
                {this.state.election != null && <VotesByTimeSlot election_id={this.props.match.params.election_id} messages={this.state.messages} election={this.state.election}/>}
                <br/><br/>

                {this.state.snapshot != null && Object.keys(this.state.snapshot.districts).length > 1 && (

                    <TurnoutTable snapshot={this.state.snapshot} messages={this.state.messages}/>
                )}

                {this.state.snapshot && this.state.snapshot.drop_in_places && (

                    <TurnoutDropInTable snapshot={this.state.snapshot} messages={this.state.messages}/>
                )}
                <br/><br/>
                <Footer election={this.state.election} messages={this.state.messages}/>
            </div>
        );
    }
}


export default Home;