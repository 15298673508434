import React, {Component, Fragment} from "react";
import {getMessage} from "./util";

import "./TurnoutDropInTable.css";

class TurnoutDropInTable extends Component {

    render() {

        return (
            <Fragment>
                <div className="row justify-content-center EvColor text-white p-3">
                    <div className="col-12 col-sm-11 col-md-9 col-lg-7">
                        <h4>{getMessage('turnout_drop_in_table',this.props.messages)}</h4>
                        <smal>{getMessage('turnout_drop_in_table_excerpt',this.props.messages)}</smal>
                    </div>
                </div>
                <br/>
                <div className="row justify-content-center">

                    <div className="col-12 col-sm-11 col-md-9 col-lg-7  p-0">
                        <table className="table table-bordered table-condensed table-turnout">
                            <thead>
                            <tr>
                                <th>{getMessage('local',this.props.messages)}</th>
                                <th>{getMessage('votes',this.props.messages)} </th>
                            </tr>
                            </thead>
                            <tbody>
                            {Object.keys(this.props.snapshot.drop_in_places).map(d => (
                                <DropInPlaceRow
                                    key={d}
                                    drop_in_place={this.props.snapshot.drop_in_places[d]}
                                />
                            ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </Fragment>
        );
    }

}

class DropInPlaceRow extends Component {
    constructor(props) {
        super(props);
        this.state = {
            collapsed: true
        };
    }

    doCollapse = event => {
        this.setState({collapsed: !this.state.collapsed});
    };

    render() {

        return (
            <React.Fragment>
                <tr className="district-row" onClick={this.doCollapse}>
                    <td>{this.props.drop_in_place.name}</td>

                    <td>
                        <div className="text-center">
                            {(this.props.drop_in_place.voters).toLocaleString(
                                navigator.language,
                                {maximumFractionDigits: 0}
                            )}

                        </div>


                    </td>
                </tr>
                {this.state.collapsed === false &&
                Object.keys(this.props.drop_in_place.drop_in_places_boxes).map(bx => (
                    <BoxRow
                        box={this.props.drop_in_place.drop_in_places_boxes[bx]}
                        key={this.props.drop_in_place.district_id + "-" + bx}
                    />
                ))}
            </React.Fragment>
        );
    }
}

class BoxRow extends Component {
    render() {

        return (
            <tr className="ballotbox-row">
                <td>{this.props.box.name}</td>
                <td>
                    <div className="text-center">
                        {(this.props.box.voters).toLocaleString(
                            navigator.language,
                            {maximumFractionDigits: 0}
                        )}

                    </div>

                </td>
            </tr>
        );
    }
}

export default TurnoutDropInTable;